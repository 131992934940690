import * as React from 'react'
import { Layout } from '../components/Layout'
import { MarkdownReader } from '../components/Text'
import type { HeadFC } from 'gatsby'
import { SEO } from '../components/SEO'

const IndexPage = () => {
  return (
    <Layout>
      <div className="text-box">
        <MarkdownReader filename="CHANGELOG" />
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO title="Lakshmi - Changelog" pathname="/changelog"></SEO>
